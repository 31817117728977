import React from "react";
import axios from "axios";
import { InputMask } from "@react-input/mask";
import { useScrollLock, useOnClickOutside } from "usehooks-ts";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const PopupInfo: React.FC<Props> = ({ isOpen, onClose }) => {
    const { lock, unlock } = useScrollLock();
    const [phone, setPhone] = React.useState("");
    const [isValid, setIsValid] = React.useState(false);
    const [isSubmit, setIsSubmit] = React.useState(false);

    const RefPopup = React.useRef(null);

    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
    };

    const onSubmit = () => {
        axios
            .post("https://api.hobjob.ru/api/zabota", {
                addService: "-",
                startDay: "-",
                type: "-",
                tarif: "-",
                phone,
                date: "-",
            })
            .then(() => {
                setIsSubmit(true);

                // @ts-ignore
                var ym = window.ym || (window.ym = []);
                ym(98349857, "reachGoal", "form");
            });
    };

    useOnClickOutside(RefPopup, onClose);

    React.useEffect(() => {
        isOpen ? lock() : unlock();
    }, [isOpen]);

    return (
        <AnimatePresence mode="wait">
            {isOpen && (
                <>
                    <motion.div
                        className="popup-backdrop"
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                    ></motion.div>

                    <motion.div
                        key="popup"
                        className="popup"
                        initial={{ opacity: 0, y: 30 }}
                        exit={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="popup-content" ref={RefPopup}>
                            <div
                                className="popup-content-close"
                                onClick={onClose}
                            >
                                <svg
                                    viewBox="0 0 29 29"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M27 2L2 27.0001"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2 2L27 27.0001"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>

                            {isSubmit ? (
                                <h2 className="popup-content__title">
                                    Спасибо! <br /> Скоро с вами свяжется
                                    менеджер
                                </h2>
                            ) : (
                                <>
                                    <h2 className="popup-content__title">
                                        Чтобы узнать стоимость, оставьте свой
                                        номер, мы&nbsp;напишем вам
                                        в&nbsp;WhatsApp или позвоним вам, если
                                        не&nbsp;найдем ваш WhatsApp
                                    </h2>

                                    <div className="popup-content-input">
                                        <InputMask
                                            mask="+7 ___ ___-__-__"
                                            replacement={{ _: /\d/ }}
                                            className="input popup-content-input__field"
                                            placeholder="Ваш номер телефона"
                                            onChange={onChangePhone}
                                            onMask={(event) => setIsValid(event.detail.isValid)}
                                        />
                                    </div>

                                    <button
                                        onClick={onSubmit}
                                        className={`btn ${!isValid && "disabled"} popup-content__btn`}
                                        disabled={!isValid}
                                    >
                                        Узнать стоимость
                                    </button>
                                </>
                            )}
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default PopupInfo;
