import React from "react";
import { useDispatch } from "react-redux";

import { setOpenQuiz } from "../../redux/actions/quiz";

import Logo from "../../assets/images/logo.svg";

const Header: React.FC = () => {
    const dispatch = useDispatch();

    const [isVisibleHeader, setIsVisibleHeader] = React.useState(true);

    const [prevScrollpos, setPrevScrollpos] = React.useState<number>(0);
    const [currentScrollPos, setCurrentScrollPos] = React.useState<number>(0);

    React.useEffect(() => {
        // document.addEventListener("mousedown", handHeaderModalMenu);
        // document.addEventListener("touchstart", handHeaderModalMenu);
        // return () => {
        // 	document.removeEventListener("mousedown", handHeaderModalMenu);
        // 	document.removeEventListener("touchstart", handHeaderModalMenu);
        // };
    }, []);

    React.useEffect(() => {
        const wrapper = document.getElementById("wrapper");

        if (wrapper) {
            setPrevScrollpos(window.pageYOffset);

            document.body.onscroll = function () {
                setCurrentScrollPos(window.pageYOffset);

                if (prevScrollpos > currentScrollPos && currentScrollPos > 0) {
                    setIsVisibleHeader(true);
                } else if (currentScrollPos > 200) {
                    setIsVisibleHeader(false);
                }

                setPrevScrollpos(currentScrollPos);
            };
        }
    }, [currentScrollPos]);

    const onClickOpenQuizForm = () => {
        dispatch(setOpenQuiz(true));
    };

    return (
        <header className={`header ${isVisibleHeader ? "active" : ""}`}>
            <div className="container">
                <div className="header-wrapper">
                    <div className="header-logo">
                        <img
                            src={Logo}
                            alt="забота"
                            className="header-logo__image"
                        />
                    </div>

                    <a href="tel:+79055759638" className="header__phone">
                        +7 905 575-96-38
                    </a>

                    {/* <nav className="header-menu">
						<a href="#" className="header-menu__link">Какие будут медсестры?</a>
						<a href="#" className="header-menu__link">Цены</a>
						<a href="#" className="header-menu__link">Контакты</a>
					</nav> */}

                    {/* <button className={`btn small header__btn ${currentScrollPos > 300 ? "visible" : ""}`} onClick={onClickOpenQuizForm}>
						Хочу узнать стоимость
					</button> */}
                </div>
            </div>
        </header>
    );
};

export default Header;
